import {RootState} from "@/store/types";
import {PartsSearchState} from "@/store/PartsSearch/types";
import {Module} from "vuex";
import {capacitorTable} from "@/components/ExperimParts/CapacitorTabFilters/CapacitorTable";
import {resistanceTab} from "@/components/ExperimParts/ResistanceTabFilters/ResistanceTable";
import {diodesTable} from "@/components/ExperimParts/DiodesTabFilter/DiodesTable";
import {ICTab} from "@/components/ExperimParts/ICTabFilters/ICTable";
import axios from "axios";
import {transistorsTable} from "@/components/ExperimParts/TransistorsTabFilter/TransistorsTable";
import {inductorsTable} from "@/components/ExperimParts/InductorsTabFilter/InductorsTable";
import {switchesTable} from "@/components/ExperimParts/SwitchesTabFilter/SwitchesTable";
import {fusesTable} from "@/components/ExperimParts/FusesTabFilters/FusesTable";
import {connectorsTable} from "@/components/ExperimParts/ConnectorsTabFilters/ConnectorsTable";

export const PartsSearch:Module<PartsSearchState, RootState> = {
  namespaced: true,
  state: {
    isLoading: false,
    loadingQtySource:null,
    selectedCategory:2,
    page:1,
    totalPages:1,
    totalAmount:0,
    hideZeroQty: false,
    isLoadingLocs:false,
    oldScrollY:0,
    preferenceMeasurementSystem:null,
    partsCategories:[],
    items:[

    ],
    loc_columns: [
      { key: "loc", label: "Location" },
      { key: "loc_qty", label: "Qty" },
      { key: "loc_reserved", label: "Reserved" },
      { key: "loc_available", label: "Available" },
    ],
    qty_columns: [
      { key: "qty", label: "Qty" },
      { key: "reserved", label: "Reserved" },
      { key: "available", label: "Available" },
    ],
    sortingValues: {
      resistance: 'asc',
      qty:null,
    },
    sortingOptions: [
      null,
      'asc',
      'desc'
    ],
    filtersOptions: {
      capacitance: {
        values:[],
        divs:[],
        rangeList:[]
      },
      manufacturers:[

      ],
      resistance:[

      ],
      voltage: {
        values:[

        ],
        divs:[

        ],
        rangeList:[

        ]
      },
      dielectrics:[

      ],
      package:[

      ],
      rangeList: [

      ],
      divs:[]
    },
    divs:[

    ],
    partsToShow:[],
    filtersValues: {
      manufacturers:[

      ],
      resistance:[

      ],
      dielectrics:[

      ],
      voltage:[

      ],
      capacitance: [

      ],
      tolerance:[

      ],
      qty: [

      ],
      package: [

      ],
      packageMountType: null
    },
    columns:{
      1:capacitorTable.columns,
      2:resistanceTab.columns,
      4:diodesTable.columns,
      5:transistorsTable.columns,
      6:connectorsTable.columns,
      7:inductorsTable.columns,
      8:ICTab.columns,
      11:switchesTable.columns,
      19:fusesTable.columns
    },
    categoryTabs:{
      1:capacitorTable,
      2:resistanceTab,
      4:diodesTable,
      5:transistorsTable,
      6:connectorsTable,
      7:inductorsTable,
      8:ICTab,
      11:switchesTable,
      19:fusesTable
    }
  },
  getters:{
    getManufacturers: (state) => state.filtersOptions.manufacturers,
    getPartsCategories: (state) => state.partsCategories,
    getPartsToShow: (state) => state.partsToShow,
    getPackageName: (state) => (packageObj:any) => {
      if(!packageObj) {
        return null;
      }
      // console.log(packageObj);
      if(state.preferenceMeasurementSystem === 'metric' && packageObj.metric_name) {
        let postfix = '';
        if(packageObj.cat_name) {
          postfix = ` - ${packageObj.cat_name.replace('_', ' ')}`;
        }
        return packageObj.metric_name + postfix;
      }
      if(state.preferenceMeasurementSystem === 'imperial' && packageObj.imperial_name) {
        let postfix = '';
        if(packageObj.cat_name) {
          postfix = ` - ${packageObj.cat_name.replace('_', ' ')}`;
        }
        return packageObj.imperial_name+postfix;
      }
      let postfix = '';
      if(packageObj.cat_name) {
        postfix = ` - ${packageObj.cat_name.replace('_', ' ')}`;
      }
      return (packageObj.name ?? packageObj.pkgName)+postfix;
    },
    getRangeLabel: (state) => (props:any) => {
      let {type} = props;
      const {value} = props;
      if(!type) {
        type = 'resistance';
      }
      switch (type) {
        case 'dielectric':
          return state.filtersOptions.dielectrics.find((el:any)=>el.id == value)?.value ?? ''
        case 'voltage':
          return state.filtersOptions.voltage.rangeList.find((el:any)=>el.value === value.rangeValue)?.label ?? ''
        case 'resistance':
          return state.filtersOptions.rangeList.find((el:any)=>el.value === value.rangeValue)?.label ?? ''
        case 'capacitance':
          return state.filtersOptions.capacitance.rangeList.find((el:any)=>el.value === value.rangeValue)?.label ?? ''
      }
      return '';
    },
    getSortIcon: (state) => (filter_name:string) =>{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const sorted = state.sortingValues[filter_name];
      if(sorted){
        return sorted === 'asc' ? 'arrow_drop_down' : 'arrow_drop_up';
      } else {
        return 'sort'
      }
    },
    columns(state){
      const category_id = state.selectedCategory;
      return state.columns[category_id];
    },
    filteredItems(state){
      let toReturn = state.items;
      if(state.hideZeroQty && !state.isLoadingLocs) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toReturn =  state.items.filter(item => item?.qty?.available > 0);
      }
      if(state.sortingValues.qty && !state.isLoadingLocs){
        toReturn = toReturn.sort((a,b) => {
          if(state.sortingValues.qty === 'asc') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return b.qty.available - a.qty.available
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return a.qty.available - b.qty.available
        })
      }
      if(state.partsToShow.length > 0) {
        return toReturn.sort((a:any, b:any) => {
          if (state.partsToShow.includes(a.id)) return -1; // move priority IDs to the top
          if (state.partsToShow.includes(b.id)) return 1; // move priority IDs to the top
          return 0; // keep original order for non-priority IDs
        });
      }

      return toReturn;
    },
    hiddenItemsAmount(state,getters){
      if (state.hideZeroQty) {
        return state.items.length - getters.filteredItems.length;
      }
      return 0;
    },
    rawPackagesList(state){
      const packages = [];
      for (let i = 0; i < state.filtersOptions.package.length; i++) {
        const packageCategory = state.filtersOptions.package[i];
        for (let j = 0; j < packageCategory.packages.length; j++) {
          packages.push({
            ...packageCategory.packages[j],
            cat_name: packageCategory.name
          });
        }
      }
      return packages;
    },
  },
  actions:{
    changePartCategory({commit,state, dispatch},category_id){
      const allowed_categories = [1,2,4,8,5,7,11,19,6];
      let next_category_id = null;
      if(category_id) {
        if(!allowed_categories.includes(category_id)) {
          next_category_id = allowed_categories[0];
          // commit('setPartCategory',allowed_categories[0]);
        } else {
          next_category_id = category_id;
          // commit('setPartCategory',category_id);
        }
      } else {
        const actual_index = allowed_categories.indexOf(state.selectedCategory);
        if(actual_index < allowed_categories.length - 1) {
          next_category_id = allowed_categories[actual_index + 1];
          // commit('setPartCategory',allowed_categories[actual_index + 1]);
        } else {
          next_category_id = allowed_categories[0];
          // commit('setPartCategory',allowed_categories[0]);
        }
      }

      commit('setPartCategory',next_category_id);
      commit('clearAllFilters');

      switch (next_category_id) {
        case 2:
          state.sortingValues.resistance = 'asc';
          break;
        default:
            break;
      }
      dispatch('loadManufacturers');
      dispatch('loadParts',true);
    },
    toggleMeasurementSystem({state}, value){
      if(value !== undefined) {
        return state.preferenceMeasurementSystem = value;
      }
      if(state.preferenceMeasurementSystem === 'metric') {
        return state.preferenceMeasurementSystem = null;
      }
      if(state.preferenceMeasurementSystem === 'imperial') {
        return state.preferenceMeasurementSystem = 'metric';
      }
      if(state.preferenceMeasurementSystem === null) {
        return state.preferenceMeasurementSystem = 'imperial';

      }
    },
    async loadFiltersOptions({ dispatch}){
      await Promise.all([
        dispatch('loadManufacturers'),
        dispatch('loadResistanceOptions'),
        dispatch('loadVoltageOptions'),
        dispatch('getDielectricOptions'),
        dispatch('loadPackageOptions'),
        dispatch('loadCapacitanceOptions')
      ]);
    },
    async loadPartCategories({ state}){
      state.partsCategories = [];
      const res = await axios.get('/api/parts/categories/all');
      if(res.data){
        state.partsCategories = res.data.sort((el1:any,el2:any)=>el1.id - el2.id);
      }
    },
    setDielectricFilter({dispatch,state}, {dielectric,dielectric_name = null,isPreventPartLoad = false}){
      if(dielectric_name) {
        const diel = state.filtersOptions.dielectrics.find(el=>el.value == dielectric_name);
        if(!diel) return;
        state.filtersValues.dielectrics = [diel.id];
      } else {
        state.filtersValues.dielectrics = [dielectric];
      }
      if(!isPreventPartLoad) {
        dispatch('loadParts',true);
      }
    },
    setVoltageFilter({dispatch,state}, {voltage,div,rangeValue, isPreventPartLoad = false}){
      let divInFilter = state.filtersOptions.voltage.divs.find((el)=>el.id === div);
      if (!divInFilter) {
        divInFilter = {
          id:div,
          name:'V',
          multi:1,
        }
      }
      state.filtersValues.voltage = [
        {
          voltage:voltage,
          div:divInFilter,
          rangeValue:rangeValue
        }
      ];
      if(!isPreventPartLoad) {
        dispatch('loadParts',true);
      }
    },
    setCapacitanceFilter({dispatch,state}, {capacitance,div,rangeValue, isPreventPartLoad = false}){
      let divInFilter = state.filtersOptions.capacitance.divs.find((el)=>el.id === div);
      if (!divInFilter) {
        divInFilter = {
          id:div,
          name:'pF',
          multi:1,
        }
      }
      state.filtersValues.capacitance = [
        {
          capacitance:capacitance,
          div:divInFilter,
          rangeValue:rangeValue
        }
      ];
      if(!isPreventPartLoad) {
        dispatch('loadParts',true);
      }
    },
    setResistanceToleranceFilter({dispatch,state}, {resistance,tolerance,div,rangeValue,isPreventPartLoad = false}){
      let divInFilter = state.filtersOptions.divs.find((el:any)=>el.id === div);
      if(!divInFilter){
        divInFilter = {
          id:div,
          name:'Ω',
          multi:1,
        }
      }
      state.filtersValues.resistance = [
        {
          resistance:resistance,
          div:divInFilter,
          tolerance:tolerance,
          rangeValue:rangeValue
        }
      ]
      if(!isPreventPartLoad) {
        dispatch('loadParts',true);
      }
    },
    setManufactureFilter({dispatch,state}, value){
      if(state.filtersValues.manufacturers.find((el)=>el.id === value.id)){
        return;
      }
      state.filtersValues.manufacturers = [
        value
      ];
      dispatch('loadParts',true);
    },
    setPackageFilter({dispatch,state}, {value,mountType,isPreventLoadParts = false}){
      if(!value && !mountType) {
        return;
      }
      if(value) {
        state.filtersValues.package = [
          value
        ];
      }
      if(mountType) {
        state.filtersValues.packageMountType = mountType;
      }
      if(!isPreventLoadParts) {
        dispatch('loadParts',true);
      }
    },
    async loadCapacitanceOptions({ state}){
      const res = await axios.get('/api/parts/getCapacitanceOptions');
      state.filtersOptions.capacitance.values = res.data.options;
      state.filtersOptions.capacitance.divs = res.data.divs;
      state.filtersOptions.capacitance.rangeList = res.data.rangeList;
    },
    async loadResistanceOptions({ state }){
      const res = await axios.get('/api/parts/getResistanceOptions');
      state.filtersOptions.resistance = res.data.options;
      state.filtersOptions.divs = res.data.divs;
      state.filtersOptions.rangeList = res.data.rangeList;
    },
    async loadPackageOptions({ state }){
      const categories = Object.keys(state.categoryTabs);
      const packages_ids = [];
      for (let i = 0; i < categories.length; i++) {
        const cat_id: number = parseFloat(categories[i]);
        packages_ids.push(...state.categoryTabs[cat_id].packages_ids);
      }
      const package_categories_ids = packages_ids.filter((value, index, array)=> {
        return array.indexOf(value) === index;
      }).sort()
      const res = await axios.get('/api/getPackages',{
        params: {
          category_ids:package_categories_ids// 50,78 - Resistors, 42,43,82 - Capacitors, 20,22 - Diodes, 25,37,38,39
        }
      });
      state.filtersOptions.package = res.data.categories;
    },
    async getDielectricOptions({ state }){
      const res = await axios.get('/api/parts/getDielectricOptions');
      state.filtersOptions.dielectrics = res.data;
    },
    async loadVoltageOptions({ state }){
      const res = await axios.get('/api/parts/getVoltageOptions');
      state.filtersOptions.voltage.values = res.data.options;
      state.filtersOptions.voltage.divs = res.data.divs;
      state.filtersOptions.voltage.rangeList = res.data.rangeList;
    },
    async loadManufacturers({ state }){
      const res = await axios.get('/api/parts/brands/mini',{
        params: {
          categoryFilter:state.selectedCategory
        }
      });
      state.filtersOptions.manufacturers = res.data;
    },
    async getFiltersOptionsForRequest({state}){
      const specs = [];
      const package_ids = [];
      let orderBy = null;
      for (let i = 0; i < state.filtersValues.resistance.length; i++) {
        const value = state.filtersValues.resistance[i].resistance;
        if(value === null) {
          specs.push({
            specification_id:8,
            div_id:state.filtersValues.resistance[i].div.id,
            value:1,
            range: 0,
            option_id:null,
            mode:2,
          })
          specs.push({
            specification_id:8,
            div_id:state.filtersValues.resistance[i].div.id,
            value:1000,
            range: 0,
            option_id:null,
            mode:3,
          })
        } else {
          const range = state.filtersValues.resistance[i].rangeValue;
          specs.push({
            specification_id:8,
            div_id:state.filtersValues.resistance[i].div.id,
            value,
            range,
            option_id:null,
            mode:1,
          })
        }

      }

      for (let i = 0; i < state.filtersValues.capacitance.length; i++) {
        const value = state.filtersValues.capacitance[i].capacitance;
        const div_id = state.filtersValues.capacitance[i].div.id;
        if(value === null) {
          specs.push({
            specification_id:10,
            div_id,
            value:1,
            range: 0,
            option_id:null,
            mode:2,
          })
          specs.push({
            specification_id:10,
            div_id,
            value:1000,
            range: 0,
            option_id:null,
            mode:3,
          })
        } else {
          const range = state.filtersValues.capacitance[i].rangeValue;
          specs.push({
            specification_id:10,
            div_id,
            value,
            range,
            option_id:null,
            mode:1,
          })
        }
      }

      for (let i = 0; i < state.filtersValues.dielectrics.length; i++) {
        const value = state.filtersValues.dielectrics[i];
        specs.push({
          specification_id:12,
          div_id:null,
          value,
          range: null,
          option_id:value,
          mode:1,
        });
      }

      for (let i = 0; i < state.filtersValues.voltage.length; i++) {
        const value = state.filtersValues.voltage[i].voltage;
        const div_id = state.filtersValues.voltage[i].div.id;
        if(value === null) {
          specs.push({
            specification_id:11,
            div_id,
            value:1,
            range: 0,
            option_id:null,
            mode:2,
          })
          specs.push({
            specification_id:11,
            div_id,
            value:1000,
            range: 0,
            option_id:null,
            mode:3,
          })
        } else {
          const range = state.filtersValues.voltage[i].rangeValue;
          specs.push({
            specification_id:11,
            div_id,
            value,
            range,
            option_id:null,
            mode:1,
          })
        }
      }

      if(state.filtersValues.package.length > 0) {
        for (let i = 0; i < state.filtersValues.package.length; i++) {
          const value = state.filtersValues.package[i].id;
          package_ids.push(value);
        }
      }
      if(state.sortingValues.resistance) {
        orderBy = {
          colID:8,
          dir:state.sortingValues.resistance
        };
      }
      return {
        specs,
        package_ids,
        orderBy
      }
    },
    async loadParts({state,dispatch, getters},resetPage = false){
      dispatch('cancelQtyLoad');
      state.isLoading = true;
      try {
        const {specs, package_ids, orderBy} = await dispatch('getFiltersOptionsForRequest');
        const page = !resetPage?state.page:1;
        const data = {
          "keyword":"",
          page,
          perPage:30,
          "isShowUnconfirmed":false,
          "brands":state.filtersValues.manufacturers.map((el)=>el.id),
          "categories":state.selectedCategory,
          "specs":specs,
          orderBy,
          package_ids,
          parts_ids:getters.getPartsToShow,
          "filter_package_id":null
        };
        if(state.filtersValues.packageMountType) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data['filter_package_mount_type'] = state.filtersValues.packageMountType;
        }
        const res = await axios.post('/api/parts/search', data)
        state.totalPages = res.data.totalPages;
        state.totalAmount = res.data.totalAmount;
        state.items = res.data.data;
        console.log('loading',res.data)
        dispatch('loadQtyLocs');
      } catch (e) {
        console.error(e)
      } finally {
        state.isLoading = false;
      }
    },
    async cancelQtyLoad({state}){
      if(state.loadingQtySource) {
        state.loadingQtySource.cancel('Operation canceled by the user.');
        console.log('canceled');
      }
    },
    async loadMoreParts({state,dispatch, getters}){
      if(state.page*15 > state.totalAmount) return;
      state.page++;
      try {
        const {specs, package_ids, orderBy} = await dispatch('getFiltersOptionsForRequest');
        const page = state.page;
        const data = {
          "keyword":"",
          page,
          perPage:15,
          "isShowUnconfirmed":false,
          "brands":state.filtersValues.manufacturers.map((el)=>el.id),
          "categories":state.selectedCategory,
          "specs":specs,
          orderBy,
          package_ids,
          parts_ids:getters.getPartsToShow,
          "filter_package_id":null
        }
        if(state.filtersValues.packageMountType) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data['filter_package_mount_type'] = state.filtersValues.packageMountType;
        }
        const res = await axios.post('/api/parts/search', data);
        state.totalPages = res.data.totalPages;
        state.totalAmount = res.data.totalAmount;
        state.items = state.items.concat(res.data.data);
        console.log('loading',res.data)
        dispatch('loadQtyLocs');
      } catch (e) {
        console.error(e)
      } finally {
        state.isLoading = false;
      }
      console.log('load more parts');
    },
    async loadQtyLocs({state}) {
      state.isLoadingLocs = true;
      try {
        for (let i = 0; i < state.items.length; i++) {
          const item = state.items[i];
          item.qty = null;
          item.locs = null;
          item.price = null;
        }
        const cancelToken = axios.CancelToken;
        state.loadingQtySource =  cancelToken.source();
        const res = await axios.post('/api/parts/odoo/findQtyLocs', {
          ids:state.items.map((el)=>el.id)
        },{
          cancelToken: state.loadingQtySource.token
        });
        for (let i = 0; i < state.items.length; i++) {
          const item = state.items[i];
          const synced = res.data.find((el:any)=>el.id === item.id);
          if(synced) {
            item.qty = {
              qty:synced.qty,
              reserved:synced.reserved,
              available:synced.available
            };
            item.locs = synced.locs;
            item.price = (synced.price) ? synced.price : undefined;
          } else {
            item.qty = undefined;
            item.locs = undefined;
            item.price = undefined;
          }
        }
      } catch (e) {
        console.error(e)
        for (let i = 0; i < state.items.length; i++) {
          const item = state.items[i];
          item.qty = undefined;
          item.locs = undefined;
        }
      } finally {
        state.isLoadingLocs = false;
      }
    },
    changeSort({state, dispatch},prop:string){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const actualSort = state.sortingValues[prop];
      const actualSortIndex = state.sortingOptions.findIndex((el)=>el === actualSort);
      let nextSortIndex = actualSortIndex + 1;
      if (actualSortIndex === state.sortingOptions.length - 1) {
        nextSortIndex = 0;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.sortingValues[prop] = state.sortingOptions[nextSortIndex];
      if(prop === 'qty') {
        return;
      }
      dispatch('loadParts',true);
    },
    async addOtherRecords({state, dispatch}) {
      if(state.isLoading) return;
      console.log('loading other options');
      state.isLoading = true;
      await dispatch('loadMoreParts');
      state.isLoading = false;
    }
  },
  mutations:{
    switchHideZeroQty(state){
      state.hideZeroQty = !state.hideZeroQty;
    },
    clearAllFilters(state){
      state.filtersValues = {
        dielectrics:[],
        manufacturers:[],
        resistance:[],
        voltage: [],
        capacitance: [],
        tolerance: [],
        qty: [],
        package: [],
        packageMountType: null
      }
      state.sortingValues = {
        resistance: null,
        qty:null,
      }
    },
    setPartCategory(state,category_id){
      state.selectedCategory = category_id;
    },
    setPartsToShow(state,parts:number[]){
      state.partsToShow = parts;
    },
    clearState(state){
      state.partsToShow = [];
      state.isLoading = false;
      state.loadingQtySource = null;
      state.selectedCategory = 1;
      state.page = 1;
      state.totalPages = 1;
      state.totalAmount = 0;
      state.hideZeroQty = true;
      state.isLoadingLocs = false;
      state.oldScrollY = 0;
      state.preferenceMeasurementSystem = null;
      state.partsCategories = [];
      state.items = [];
      state.filtersValues = {
        dielectrics:[],
        manufacturers: [],
        resistance: [],
        voltage: [],
        capacitance: [],
        tolerance: [],
        qty: [],
        package: [],
        packageMountType: null
      }
      state.sortingValues = {
        resistance: null,
        qty:null,
      }
    }
  }
}
