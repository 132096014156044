import {Module} from "vuex";
import {RootState} from "@/store/types";
import {PartPageState} from "@/store/PartPage/types";
import axios from "axios";
import {useToast} from "vuestic-ui";
import {useToastWrapper} from "@/utils/SetupToastWrapper";

export const PartPage:Module<PartPageState, RootState> = {
  namespaced: true,
  state: {
    categories: [],
    manufacturers: [],
    series: [],
    specs:[]
  },
  getters: {
    getCategories(state) {
      return state.categories
    },
    getManufacturers(state) {
      return state.manufacturers
    },
    getSeries(state) {
      return state.series
    },
    getSpecs(state) {
      return state.specs
    }
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    },
    setManufacturers(state, manufacturers) {
      state.manufacturers = manufacturers
    },
    setSeries(state, series) {
      state.series = series
    },
    setSpecs(state, specs) {
      state.specs = specs
    }
  },
  actions: {
    async loadCategories({ commit }) {
      const res = await axios.get('/api/parts/categories/all');
      if(res.data){
        const filtered = res.data.sort((el1:any,el2:any)=>el1.id - el2.id);
        commit('setCategories', filtered);
      }
    },
    async loadManufacturers({ commit },categoryId) {
      const res = await axios.get('/api/parts/brands/mini',{
        params: {
          categoryFilter:categoryId
        }
      });
      commit('setManufacturers', res.data);
    },
    async loadSpecs({commit,rootState}){
      const response = await axios.get(`/api/spec`);
      if(response.data.error) useToastWrapper().init({message: response.data.message, color: 'danger'})
      commit('setSpecs',response.data);
    }
  }
}
