import { createRouter, createWebHistory } from 'vue-router'

import routes from "@/router/routes";
import store from "@/store";
import {nextTick} from "vue";
const router = createRouter({
  history: createWebHistory(),
  routes
})
const DEFAULT_TITLE = 'Circuits haha'
router.afterEach((to, from) => {
  nextTick(() => {
    let title = to.meta.title as string || DEFAULT_TITLE;
    if(Object.keys(to?.params).length > 0) {
      title += ': ' + to.params.id
    }
    document.title = title;
  })
})
router.beforeEach((to, from, next) => {
  if(!to.fullPath.startsWith('/login') && !to.fullPath.startsWith('/register') && !to.fullPath.startsWith('/confirmEmail')) {
    store.commit('updateRouterHistory',to.fullPath)
  }
  store.dispatch('fetchAccessToken').then(_=>{
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if(!store.getters.isConfirmedEmail && to.name !== "confirmEmail") {
        next('/confirmEmail')
        return;
      }

      if(to.name == "confirmEmail" && store.getters.isConfirmedEmail) {
        next('/projects')
        return
      }

      if (store.state.token) {
        if(to.name == "login"){
          next('/projects')
          return
        }
        next()
        return
      }
      next('/login')
    }

    if (store.state.token) {
      if(to.name == "login" || to.name == "register"){
        next('/projects')
        return
      }
    }
    next()
  })
})

export default router
